import { Supplier, SupplierCollection } from 'models/entities/supplier';
import { Business, BusinessCollection } from 'models/entities/business';

import { Condition } from './models/condition';
import { ReadBusinessGql, BusinessDataToInit } from '../index.model';

class Model {

  readonly condition: Condition;
  readonly collection?: SupplierCollection;
  readonly list?: Supplier[];
  readonly business?: Business;
  readonly businesses?: BusinessCollection;
  readonly disableAction: {
    onExport: boolean;
  };

  private readonly readBusinessGql: ReadBusinessGql;

  constructor() {
    this.condition = new Condition();
    this.readBusinessGql = new ReadBusinessGql();
    this.disableAction = {
      onExport: false,
    };
  }

  init(data: BusinessDataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  async readBusiness(code: string): Promise<this> {
    const readBusinessGql = await this.readBusinessGql.fetch({ code });
    const { business, businesses } = readBusinessGql;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, business, businesses, readBusinessGql });
  }

  async read(): Promise<this> {
    if (!this.business) throw new Error('business is undefined');
    const collection = await SupplierCollection.read(this.business.id!);
    const list = this.condition.filter(collection.documents);
    const disableAction = {
      onExport: !list.length,
    };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list, disableAction });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  build(): Supplier {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.business) throw new Error('business is undefined');
    return new Supplier({ businessId: this.business.id! });
  }

  add(supplier: Supplier): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(supplier));
  }

  replace(supplier: Supplier): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(supplier));
  }

  remove(supplier: Supplier): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(supplier));
  }

  private apply(collection: SupplierCollection): this {
    const list = this.condition.filter(collection.documents);
    const disableAction = this.disableAction;
    disableAction.onExport = !list.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list, disableAction });
  }

}

export { Model };