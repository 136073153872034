import React, { useState, useEffect } from 'react';

import { Supplier } from 'models/entities/supplier';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useMemory } from 'views/services/memory';
import { useBusinessCode } from 'views/services/hooks';
import { useModal, ModalResult } from 'views/services/modal';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { Root, Frame, List } from './index.styled';

const Suppliers = () => {

  const auth = useAuth();
  const memory = useMemory();
  const code = useBusinessCode();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(supplier?: Supplier) {
    const target = supplier ?? model.build();
    modal.open('edit-supplier', { target, strict: true, tell: apply });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof Supplier)) return;
    const supplier = result.target as Supplier;
    switch (result.state) {
      case 'created': setModel(model.add(supplier)); break;
      case 'updated': setModel(model.replace(supplier)); break;
      case 'deleted': setModel(model.remove(supplier)); break;
    }
  }

  function exportData() {
    const target = model.list;
    modal.open('export-suppliers', { target });
  }

  function watchAuth() {
    if (!auth.ok) return;
    if (!code) return;
    const businessScope = memory.get('business-scope');
    businessScope ? setModel(model.init(businessScope)) : model.readBusiness(code).then(model => setModel(model));
  }

  function watchBusiness() {
    if (!model.business) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  useEffect(watchAuth, [auth.ok, code]);
  useEffect(watchBusiness, [model.business]);
  useEffect(watchCollection, [model.collection]);

  return (
    <Root>
      {auth.user.admin ? (
        <Header mode={'business'} businesses={model.businesses} business={model.business} onCreate={() => edit()} disabledAction={model.disableAction} onExport={() => exportData()} />
      ) : (
        <Header mode={'business'} businesses={model.businesses} business={model.business} onCreate={() => edit()} disabledAction={model.disableAction} />
      )}
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <Frame>
          {!model.list.length ? <NoInterfaceData>No Supplier</NoInterfaceData> : (
            <List>
              <table>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>PIC</th>
                  <th>PIC Phone</th>
                  <th>PIC Email</th>
                  <th>Notes</th>
                </tr>
                </thead>
                <tbody>
                {model.list.map((it, i) => (
                  <tr key={i} onClick={() => edit(it)}>
                    <td>{it.name}</td>
                    <td>{it.picName}</td>
                    <td>{it.picPhone}</td>
                    <td>{it.picEmail}</td>
                    <td className="limiting">{it.notes}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </List>
          )}
        </Frame>
      )}
    </Root>
  );

};

export default Suppliers;