import { Supplier } from 'models/entities/supplier';

class Condition {

  readonly deleted: boolean;

  constructor() {
    this.deleted = false;
  }

  filter(all: Supplier[]): Supplier[] {
    return all.filter(it => this.check(it));
  }

  private check(supplier: Supplier): boolean {
    if (supplier.deleted !== this.deleted) return false;
    return true;
  }

}

export { Condition };